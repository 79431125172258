//NOTE 404 component takes langcode props to render the 404 page
//NOTE the style is in 404.css


import React, { Component } from 'react';
import {Link} from 'gatsby'

class Error404 extends Component {

  render() {
    if(this.props.langcode === "EN") {
      return (
        <div id="notfound">
          <div class="notfound">
            <div class="notfound-404">
              <h2>Oops!</h2>
              <h3>404 - The Page can't be found</h3>
            </div>
            <Link className="btn" to={`/${this.props.langcode.toLowerCase()}`}>Go TO Homepage</Link>

          </div>
        </div>
      );
    }

    else {
      return (
        <div id="notfound">
          <div class="notfound">
            <div class="notfound-404">
              <h2>Oops!</h2>
              <h3>404 - Η σελιδα δεν βρεθηκε</h3>
            </div>
            <Link className="btn" to={`/${this.props.langcode.toLowerCase()}`}>ΑΡΧΙΚΗ</Link>

          </div>
        </div>
      );
    }

  }

}

export default Error404;
