import React, { Component } from 'react';
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Error404 from '../components/404'
import {Container} from 'react-bootstrap'

import pathspliter from '../functions/pathspliter'

class NotFound extends Component {

  render() {
    return (
      <div>
      <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer} path={this.props.path} menu={this.props.pageContext.menu}/>
      <Breadcrumb path={pathspliter(this.props.path)} title={this.props.pageContext.title}/>
        <Container>
        <section className="py-6">
      <Error404 langcode={this.props.pageContext.langcode}/>
      </section>
      </Container>
      <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>
       </div>

    );
  }

}

export default NotFound;
